<div fxFlex="15%" fxFlex.md="5%" fxFlex.sm="2%" fxFlex.xs="2%"></div><div fxFlex fxLayout="column" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="space-evenly center" fxLayoutGap="15px" class="main hero">

    <div fxLayoutAlign="space-evenly center"><img class="herocard" src="assets/images/hero.jpg"></div>
    
    <div><h1>The Good King Henry Rienlage has died...</h1></div>
    <div><h5 fxLayoutAlign="space-evenly center">...Known to the common-folk of the Akrosi Empire as Henry the Kind, he now rests with his burdens. And discord spreads across the land and sea. Nobles of Arkos vy for the throne, while Henry’s spoiled children backstab each other. The so called Sea Princes (nothing more than pirates) become bolder. Finally, an unnatural mist spreads across the land, and with it, monsters that should have been no more than old wives tales.</h5></div>

    <div fxLayout="row wrap" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="space-evenly center" fxLayoutGap="15px">
        <mat-card class="card" routerLink="/nav">
            <img mat-card-image src="assets/images/hero2.png" alt="Preview of Rulestext">
            <mat-card-content class="cardtext">
                Info and Houserules
            </mat-card-content>
        </mat-card>

        <mat-card class="card" routerLink="/races">
            <img mat-card-image src="assets/images/hero5.png" alt="Preview of Races Page">
            <mat-card-content class="cardtext">
                Races and Stats
            </mat-card-content>
        </mat-card>
    
        <mat-card class="card" routerLink="/nav" hidden>
            <img mat-card-image src="assets/images/hero3.png" alt="Atlas of the World">
            <mat-card-content class="cardtext">
                Cities and Cultures
            </mat-card-content>
        </mat-card>

        <mat-card class="card" routerLink="/nav" hidden>
            <img mat-card-image src="assets/images/hero3.png" alt="Preview of Rulestext">
            <mat-card-content class="cardtext">
                Factions and their Goals
            </mat-card-content>
        </mat-card>

        <mat-card class="card" routerLink="/atlas">
            <img mat-card-image src="assets/images/hero1.png" alt="Atlas of the World">
            <mat-card-content class="cardtext">
                Atlas of the World
            </mat-card-content>
        </mat-card>
    
        <mat-card class="card" routerLink="/era" hidden>
            <img mat-card-image src="assets/images/hero3.png" alt="Under Construction Image">
            <mat-card-content class="cardtext">
                History & Previous Games
            </mat-card-content>
        </mat-card>
    
        <mat-card class="card" (click)="onNavigate()">
            <img mat-card-image src="assets/images/hero4.png" alt="Discord Image Link">
            <mat-card-content class="cardtext">
                Join the Discord
            </mat-card-content>
        </mat-card>
    </div>
</div><div fxFlex="15%" fxFlex.md="5%" fxFlex.sm="2%" fxFlex.xs="2%"></div>